@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
    background-color: #F5F5F5;
}

::-webkit-input-placeholder {
    color: #726F6F;
    font-weight: 600;
    font-size: 15px;
}

::-moz-placeholder {
    color: #726F6F;
    font-weight: 600;
    font-size: 15px;
}

::-ms-placeholder {
    color: #726F6F;
    font-weight: 600;
    font-size: 15px;
}

::placeholder {
    color: #726F6F;
    font-weight: 600;
    font-size: 15px;
}

.coming-soon-bg {
    background-color: #564cac;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 30px;
    padding-top: 30px;
    padding-bottom: 0px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: #ffefc0;
    height: 100vh;
    max-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 45%;
    box-sizing: border-box;
}

.coming-soon-logo {
    width: 40vw;
    max-width: 200px;
}

.coming-soon-title {
    color: #ffefc0;
    font-weight: 600;
    font-size: 20px;
    height: 20vh;
    margin: 30px 0px;
}

.store-btn {
    width: 60vw;
    max-width: 300px;
}

.slogan {
    font-weight: 500;
    font-size: 16px;
}

.coming-soon-hands {
    width: 100vw;
    max-width: 100vw;
    position: fixed;
    bottom: 0;
    display: none;
}

.verify-code-form {}

.loading-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.315);
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
}

.loading-gif {
    width: 60vw;
    height: 60vw;
}

p {
    font-size: 13px;
    font-weight: 500;
}

.text {
    color: #000000;
}

.no-m {
    margin-block-start: 0px;
    margin-block-end: 0px;
}

.m-5 {
    margin-left: 5vw;
    margin-right: 5vw;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-15 {
    margin-top: 15px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-t-25 {
    margin-top: 25px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-15 {
    margin-bottom: 15px;
}

.m-b-25 {
    margin-bottom: 25px;
}

.m-l-10 {
    margin-left: 10px;
}

.h-16 {
    height: 16px;
}

.w-80p {
    width: 80%;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.align-start {
    align-items: flex-start;
}

.center {
    align-items: center;
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}

.w-45p {
    width: 45%;
}

.col-2 {
    width: 50%;
}

.col-4 {
    width: 25%;
}

.login-screen {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    z-index: 1000;
    position: fixed;
    top: 0;
    /* background: linear-gradient(0deg, rgba(62, 62, 62, 0.4), rgba(31, 31, 31, 0.46)), url('./img_resources/login-background.jpg'); */
    /* background-image: url('./img_resources/login-background.jpg'); */
    /* background-size: cover; */
    color: #FFFFFF;
    position: relative;
    align-items: center;
    overflow: auto;
}

.content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 630px;
    padding: 0px 45px;
    box-sizing: border-box;
}

.new-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0px;
    box-sizing: border-box;
}

.logout-btn {
    color: #564CAC;
    font-weight: 700;
    font-size: 15px;
}

.logo {
    position: relative;
}

.bottom-image {
    margin: 0;
    width: 281px;
    height: 263px;
    align-self: flex-end;
    margin-right: -45px;
}

.title-text-wrapper {
    display: flex;
    flex-direction: column;
    color: #000000;
    /* padding-left: 45px; */
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
}

.title {
    font-size: 23px;
    font-weight: 800;
    margin-bottom: 30px;
    width: 60vw;
}

.instructions-text {
    margin: 0;
    width: 60vw;
}

.login-logo {
    /* position: absolute;
    top: 2vh; */
    width: 65vw;
    height: auto;
    /* left: 17.5vw; */
    margin: 2vh auto;
}

.login-container {
    margin: 0vw 5vw 0px 5vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.login-headline {
    font-weight: 500;
    font-size: x-large;
    text-shadow: 5px 1px 7px black;
}

.line-seperator {
    height: 1px;
    width: 100%;
    background-color: #564CAC;
    margin-bottom: 10px;
}

.terms-text {
    font-size: 14px;
    font-weight: 600;
    color: black;
}

.terms-link {
    color: #564CAC;
}

.button {
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 14px;
    border-radius: 24px;
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, .3); */
    outline: none;
    border: none;
    min-height: 48px;
    font-size: 17px;
    font-weight: 500;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.teal {
    background-color: #2AB7CA;
    color: white;
    text-align: center;
}

.facebook {
    background-color: #3b5998;
    margin-top: 20px;
    color: white;
}

.non-active {
    color: #797979;
    background-color: #EBEBEB;
}

.used {
    color: black;
    background-color: white;
}

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    width: 100%;
}

.slider {
    background-color: #564CAC;
    color: white;
    position: relative;
    width: 100%;
    border-radius: 25px;
    margin-bottom: 20px;
    min-height: 39px;
}

.slider-indicator {
    width: 45%;
    height: 80%;
    position: absolute;
    top: 10%;
    left: 2.5%;
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    font-weight: 600;
    transition: all 1s ease;
}

.account-type {
    width: 45%;
    border-radius: 4px;
    padding: 10px 5px;
    font-weight: 600;
}

.selected-account {
    background-color: white;
    color: black;
}

.btn-text {
    font-size: 14px;
    margin-left: 10px;
    font-weight: bold;
}

.icon {
    width: 26px;
    height: 26px;
}

.terms-of-service {
    font-size: small;
    width: 80%;
}

.spacer {
    height: 85px;
}

.spacer-small {
    height: 25px;
}

.navbar {
    display: flex;
    flex-wrap: nowrap;
    /* padding: 0px 10px 0px 10px; */
    width: 100vw;
    height: 65px;
    align-items: center;
    align-content: stretch;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.09);
    z-index: 999;
}

.navLink {
    width: 20%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: xx-small;
    justify-content: center;
    font-weight: 700;
    transition: all 1s;
}

.selectedLink {
    padding-bottom: 45px;
}

.selectedLinkImage {
    border-radius: 200px;
    margin-bottom: 7px;
    background-color: #564CAC;
    padding: 5px;
    border: 6px solid white;
    box-shadow: 0 -3px 5px 0 rgba(0, 0, 0, 0.09);
}

.display-wrapper {
    width: 100vw;
}

.tabs {
    background-color: white;
    display: flex;
    flex-direction: row;
    margin: 0px -45px;
    justify-content: space-between;
    padding: 0px 35px;
    position: relative;
}

.tab-option {
    color: #797979;
    font-weight: 600;
    font-size: 12px;
    padding: 12px 0px;
}

.selected-tab {
    color: black;
    /* border-bottom: 1.5px solid #564cac; */
}

.tab-indicator {
    position: absolute;
    left: 45px;
    bottom: 0;
    background-image: url('./img_resources/tab-indicator.svg');
    background-size: 12px 5px;
    background-position: 50% 0;
    background-repeat: no-repeat;
    height: 5px;
    border-bottom: 1.5px solid #564cac;
    width: 60px;
    transition: all 1s ease;
}

.stop-button-wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.stop-button {
    background-color: #FE4A49;
    border-radius: 17px;
    border: none;
    padding: 8px 15px;
    color: white;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header {
    display: flex;
    flex-direction: column;
    z-index: 999;
    margin-top: 11px;
}

.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-text {
    margin-bottom: 5px;
    margin-top: 5px;
}

.filters {
    width: 99%;
    height: 200px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.filter-title {
    font-size: medium;
    font-weight: bold;
    white-space: nowrap;
    margin-top: 5px;
    color: #407BFF;
    text-align: left;
    width: calc(50% - 5px);
    height: 22px;
}

.logout-div {
    width: calc(50% - 5px);
    height: 22px;
    margin-top: 5px;
    font-weight: bold;
    white-space: nowrap;
    text-align: right;
    font-size: medium;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.logout-icon {
    margin-left: 5px;
    width: 22px;
    height: 22px;
}

.form-fields-wrapper {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


/* This is the form of the new post*/


/* .img-thumbnail-wrapper {
    width: 100vw;
    padding-bottom: 100%;
    margin-left: -45px;
    margin-top: 30px;
    position: relative;
    z-index: 4;
    margin-bottom: 15px;
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
} */

.img-thumbnail-wrapper {
    width: 100%;
    max-width: 630px;
    max-height: 410px;
    height: 60vw;
    border-radius: 15px;
    background-position: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.management-preview {
    margin: 15px auto;
}

.camera-lightbox {
    padding-top: 38px;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #F5F5F5;
    bottom: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    flex-direction: column;
}

.camera {
    width: 100vw;
    height: 75vw;
    /* border: 1px black solid; */
    box-sizing: border-box;
}

.camera-controls {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.confirm-controls {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.change-camera {
    height: 33px;
    width: 33px;
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.large-circle-button {
    height: 69px;
    width: 69px;
    border-radius: 50px;
    background-color: #F4F5F7;
}

.filters-container {
    display: flex;
    flex-direction: row;
    height: 117px;
    width: 100vw;
    margin-left: -45px;
    margin-top: 15px;
    overflow: auto;
}

.filter-thumbnail {
    position: relative;
    height: 117px;
    min-width: 117px;
    margin-right: 5px;
    background-position: 50% 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.chosen-filter {
    border: 5px solid #564CAC;
    box-sizing: border-box;
}

.filter-name {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
    border-radius: 17px;
    padding: 5px 7px;
    font-size: 11px;
    font-weight: 600;
}

.thumbnail-text {
    position: absolute;
    bottom: 10%;
    font-weight: 500;
    font-size: larger;
}

.gallery-button {
    border-radius: 17px;
    padding: 8px 16px;
    padding-right: 22px;
    background-color: black;
    margin: auto;
    margin-top: 20px;
    border: none;
    max-width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.gallery-button-text {
    color: white;
}

.small-icon {
    width: 16px;
    height: 16px;
}

.img-thumbnail {
    width: 100%;
    height: 100%;
}

.post-indicator {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.post-edit {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    right: 0px;
    background-color: #2DCE89;
    border-radius: 0px 4px 4px 0px;
    width: 30px;
    height: 30px;
    z-index: 50;
    padding: 0px 4px
}

.verification-loading-text {
    font-size: 32px;
    font-weight: 600;
}

.verification-instructions {
    font-size: 20px;
    font-weight: 400;
}

.form-field {
    border: none;
    border-radius: 15px;
    min-height: 48px;
    width: 100%;
    background-color: white;
    padding: 0px 23px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}

.form-field:focus {
    outline: none;
}

.form-field:disabled {
    background-color: #DBDBDB;
    color: #797979;
}

.promotion-field {
    margin: 0px -45px;
    width: 100vw;
    border-radius: 0px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.form-field-wrapper {
    position: relative;
    width: 100%;
}

.password-icon {
    position: absolute;
    right: 20px;
    top: 16px;
    height: 16px;
    width: 16px;
}

.form-label {
    margin-top: 13px;
    margin-bottom: 5px;
    font-size: 15px;
    color: black;
    font-weight: 500;
}

.form-label-signup {
    margin: 5px;
    font-size: smaller;
    color: white;
    font-weight: 700;
}

.post-tags-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    justify-content: center;
}

.post-tags {
    position: relative;
    width: 100%;
    z-index: 49;
}

.add-tag-btn {
    position: absolute;
    right: 5px;
    bottom: 4px;
    height: 30px;
    line-height: 30;
    font-size: x-large;
    border-left: 1px solid #ced4da;
    width: 38px;
    color: #ced4da;
    z-index: 50;
    background-image: url('./img_resources/icons/plus.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}

.nice-dates-popover {
    box-shadow: none;
    border: 1px solid #ddd;
    max-width: 480px;
    transition: none;
    max-width: calc(100vw - 90px);
    left: 45px;
    margin-bottom: 200px;
}

.-today {
    font-size: 16px;
    color: #564CAC;
}

.nice-dates-day:before {
    background-color: #564CAC;
}

.time-picker-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #ffffff3b;
}

.done-btn {
    border-radius: 11px;
    background-color: #564cac;
    color: white;
    padding: 10px 25px;
    border-radius: 17px;
    margin-top: 10px;
}

.time-picker-input {
    width: 90%;
    box-sizing: border-box;
    border: none;
    border-radius: 11px;
    height: 55px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    line-height: 38px;
    color: #726F6F;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.form-field-number-wrapper {
    width: 40%;
    box-sizing: content-box;
    border: none;
    border-radius: 11px;
    height: 55px;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    line-height: 38px;
    color: #726F6F;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.form-number-field {
    border: 0px solid transparent;
    border-width: 1px;
    height: 50px;
    width: 40px;
    font-size: 15px;
    font-weight: 400;
    line-height: 38px;
    color: #726F6F;
    background-color: #ffffff;
    background-clip: padding-box;
    max-width: 70px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.no-left-padding {
    padding: 0px;
}

.form-number-field:focus {
    outline: none;
}

.currency {
    color: #726F6F;
    font-size: 15px;
}

.form-field-number-control {
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 25px;
    width: 25px;
    height: 38px;
    line-height: 38px;
    margin: 0px 5px 0px 5px;
    color: #726F6F;
}

.submit-btn {
    margin-top: 10px;
    background-color: #2DCE89;
    color: white;
    font-weight: 500;
    width: 100%;
    height: 35px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 3px 3px 3px #32325D1C;
    font-family: inherit;
    border: none;
    text-transform: capitalize;
}


/* This is the recommendations of the post page */

.ad-recommendations-wrapper {
    width: 90%;
    margin: auto;
    margin-top: 20px;
}

.tab-buttons-wrapper {
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: space-between;
}

.tab-buttons-wrapper button {
    background-color: white;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: 0px 4px 6px #32325D1C;
    padding: 14px 16px;
    transition: 0.3s;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: medium;
}

.tab-buttons-wrapper button.active {
    background-color: #5E72E4;
    color: white
}

.tabcontent {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    border-top: none;
    /* border-radius: 0px 0px 15px 15px; */
}

.template-option {
    background-color: #F4F5F7;
    color: black;
    padding: 11px 15px;
    font-weight: 600;
    box-shadow: 3px 3px 3px #00000029;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: left;
}

.template-option.active {
    background-color: #172B4D;
    color: white;
}

.manage-section-header {
    margin: 0px 0px 0px 15px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: x-large;
}

.control-buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
}

.add-user-button {
    width: 27%;
    max-width: 250px;
    padding: 0px 16px;
    border-radius: 4px;
    min-height: 34px;
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, .3); */
    border-radius: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    color: white;
    background-color: #2AB7CA;
    box-sizing: content-box;
}

.control-icon {
    width: 16px;
    height: 16px;
}

.ad-title-block {
    box-sizing: border-box;
    width: calc(100% + 90px);
    margin: 0px -45px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFFFFF;
    color: black;
    font-size: medium;
    font-weight: 500;
    min-height: 114px;
    text-transform: uppercase;
    transition: all 1s ease;
    position: relative;
    z-index: 5;
}

.purple {
    background-color: #564CAC;
    color: white;
}

.disabled {
    background-color: #F5365C;
    color: white;
}

.tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 5px;
    align-content: flex-start;
}

.tag-badge {
    font-size: x-small;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    background-color: #11CDEF;
    padding: 2px 10px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #32325D1C;
    margin-bottom: 5px;
}

.checkmark-icon {
    background-color: #2DCE89;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.data-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-evenly;
    width: 84px;
    background-color: white;
    border-radius: 11px;
    height: 73px;
    box-sizing: border-box;
    padding: 10px 0px;
}

.balance {
    width: 65%;
    margin-bottom: 10px;
}

.data-label {
    font-weight: 500;
    font-size: 11px;
    color: #797979;
}

.data-block {
    width: 100%;
    /* background-color: #F4F5F7; */
    /* border-radius: 4px; */
    /* padding: 8px 0px; */
    /* box-sizing: content-box; */
    /* box-shadow: 0px 3px 6px #32325D1C; */
    color: #172B4D;
    font-weight: 500;
    font-size: smaller;
}

.user-block {
    box-sizing: content-box;
    padding: 0px 15px;
    border-radius: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #564CAC;
    color: white;
    font-size: medium;
    font-weight: 500;
    min-height: 46px;
    /* box-shadow: 0px 3px 6px #32325D1C; */
    text-transform: uppercase;
    margin-bottom: 15px;
}

.black {
    background-color: black;
    color: white;
}

.white {
    background-color: white;
    color: black;
}

.new-user-form-button {
    width: 37%;
    height: 32px;
    font-weight: 500;
    font-size: 14px;
    color: white;
    background-color: #2AB7CA;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.red {
    background-color: #FE4A49;
}

.new-user-message {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    margin: 7.5px 0px;
}

.employee-type-btn {
    box-sizing: content-box;
    padding: 0px 15px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F5F7;
    color: black;
    font-size: medium;
    font-weight: 500;
    min-height: 46px;
    box-shadow: 0px 3px 6px #32325D1C;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.selected {
    background-color: #172B4D;
    color: white;
}

.user-text {
    font-weight: 600;
    font-size: small;
}

.form-field-management {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 38px;
    width: calc(100% - 50px);
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 14px;
}

.business-details-button {
    width: 48px;
    height: 48px;
    background-position: 50% 50%;
    background-size: 29px 29px;
    background-repeat: no-repeat;
    background-color: black;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.checkbox-button {
    width: 48px;
    height: 48px;
    background-position: 50% 50%;
    background-size: 29px 29px;
    background-repeat: no-repeat;
    background-color: #2DCE89;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #32325D1C;
    display: flex;
    justify-content: center;
    align-items: center;
}

.green {
    background-color: #2AB7CA;
}

.edit-button {
    width: 48px;
    height: 48px;
    background-position: 50% 50%;
    background-size: 29px 29px;
    background-repeat: no-repeat;
    background-color: #172B4D;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #32325D1C;
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-field-management {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 38px;
    width: 50%;
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 14px;
}

.ad-title-history {
    font-weight: bold;
    font-size: 17px;
    color: black;
    transition: all 1s ease;
}

.ad-age {
    font-weight: 500;
    color: #777780;
    font-size: 17px;
    transition: all 1s ease;
}

.ad-dateTime {
    font-weight: 600;
    font-size: 10px;
    padding: 4px 5px;
    background-color: #2AB7CA;
    color: white;
    border-radius: 3px;
    box-shadow: 0px 3px 15px #00000029;
}

.ad-block-right-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 85px;
    align-items: center;
}

.ad-price {
    font-weight: 500;
    font-size: 18px;
    color: white;
    background-color: #564CAC;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000043;
    padding: 7px 5px;
    transition: all 1s ease;
}

.ad-price-white {
    font-weight: 500;
    font-size: 18px;
    color: #564CAC;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000043;
    padding: 7px 5px;
    transition: all 1s ease;
}

.history {
    /* height: 62px; */
    margin-bottom: 10px;
}

.ad-metadata {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 83px;
    justify-content: space-between;
    /* padding: 5px 0px; */
    margin-left: 15px;
    box-sizing: border-box;
}

.balance-banner {
    width: calc(100% + 90px);
    margin: 0px -45px;
    background-color: #564CAC;
    padding-top: 37px;
    padding-bottom: 12px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    z-index: 5;
}

.total-revenue {
    font-weight: bold;
    font-size: 35px;
    margin-bottom: 16px;
    margin-top: 0px;
}

.save-icon {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.graph-title {
    margin-top: 35px;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 500;
    padding-top: 10px;
    background-color: white;
    width: calc(100% + 90px);
    margin-left: -45px;
}

.graph-container {
    width: calc(100% + 90px);
    margin-left: -45px;
    margin-bottom: 20px;
    height: 250px;
    padding: 15px 20px;
    background-color: white;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.y-axis {
    margin-bottom: 20px;
    border-right: #797979 1px solid;
    /* background-color: black; */
    height: calc(100% - 30px);
    width: 35px;
    font-size: 10px;
    text-align: right;
    padding-right: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.y-data-label {
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.graph-data-wrapper {
    height: calc(100% - 10px);
    width: calc(100% - 35px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.x-axis {
    /* width: calc(100% - 35px); */
    border-top: #797979 1px solid;
    width: 100%;
    height: 20px;
    /* background-color: yellow; */
    font-size: 10px;
    text-align: center;
    padding: 5px 10px 0px 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.graph-data {
    width: 100%;
    height: calc(100% - 20px);
    /* background-color: blue; */
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: flex-end;
}

.data-bar {
    width: 20%;
    height: 0%;
    background-color: #564CAC;
    border-radius: 5px 5px 0px 0px;
    transition: all 1s ease;
}

.x-data-label {
    width: 20%;
}

.ad-details {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px 0px 10px 0px;
    position: relative;
    z-index: 4;
}

.img-small-thumbnail {
    width: 85px;
    height: 85px;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 3px 6px #0000003C;
}

.ad-image-lightbox {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .445);
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}

.img-large-thumbnail {
    width: 80vw;
    padding-top: 80%;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.close-ad-image-lightbox {
    position: absolute;
    height: 26px;
    width: 26px;
    top: 15px;
    right: 15px;
    background-size: cover;
    background-position: center;
}

.inputGroup-active {
    background-color: #407BFF;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    position: relative;
    font-weight: 600;
    box-shadow: 1px 1px 7px #00000040;
    padding: 5px 10px;
    border-radius: 10px;
}

.radioInput {
    border: 1px solid #ced4da;
    border-radius: 50%;
    height: 32px;
    width: 32px;
}

.radioInput-active {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: 6px 6px;
    background-color: #54E0C7;
    border-radius: 50%;
    height: 36px;
    width: 36px;
}

.analysis-data-block {
    flex: 1;
    margin-left: 22px;
    height: 73px;
    background-color: white;
    border-radius: 11px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
}

.analysis-block-information {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: black;
}

.analysis-block-title {
    color: #797979;
    font-size: 12px;
    line-height: 14px;
}

.filter-type-title {
    font-size: small;
    font-weight: 600;
    white-space: nowrap;
    text-align: left;
    margin-bottom: 5px;
    margin-top: 5px;
}

.next-button {
    border-radius: 17px;
    padding: 8px 0px;
    background-color: #2AB7CA;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    border: none;
    max-width: 120px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 12px;
    font-weight: 600;
}

.age-form {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    height: 38px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.age-box {
    border: 0px solid transparent;
    border-width: 1px;
    height: 36px;
    width: 60px;
    padding: 0px 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    max-width: 160px;
    text-align: center;
}

.age-control {
    border-radius: 0.25rem;
    font-weight: 400;
    font-size: 32px;
    width: 25px;
    height: 38px;
    line-height: 38px;
    margin: 0px 5px 0px 5px;
}

.hero-image {
    width: 100vw;
    height: 30vh;
    background-image: url("./img_resources/login-background.jpg");
    background-size: cover;
    background-position: center;
    position: relative;
}

.center-flex-row {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.discover-city {
    color: white;
    font-size: xx-large;
    font-weight: bold;
    position: absolute;
    bottom: 10%;
    left: 5vw;
    text-shadow: 1px -1px 23px black;
}

.popular-tags {
    display: flex;
    flex-direction: row;
    width: 90vw;
    scroll-behavior: smooth;
    overflow: scroll;
}

.disover-tag {
    display: flex;
    flex-direction: row;
    margin-right: 5px;
    font-size: medium;
    font-weight: bold;
    white-space: nowrap;
    margin-top: 5px;
    margin-bottom: 5px;
}

.container {
    padding: 0px 45px;
    width: calc(100% - 90px);
    min-height: 90vh;
    padding-bottom: 35px;
    overflow: auto;
}

.discover-row {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.discover-row-tag {
    margin-top: 10px;
}

.discover-row-ads {
    display: flex;
    flex-direction: row;
    width: 90vw;
    height: 180px;
    scroll-behavior: smooth;
    overflow: scroll;
}

.discover-row-ads-selected {
    display: flex;
    flex-direction: row;
    width: 90vw;
    flex-wrap: wrap;
    justify-content: space-between;
}

.discover-ad {
    min-width: 35vw;
    height: 170px;
    color: white;
    text-shadow: 2px 2px 2px #000000;
    font-weight: 500;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;
    text-align: left;
    margin-right: 5vw;
    border-radius: 7px;
    box-shadow: 2px 5px 7px #000000bf;
    position: relative;
    overflow: hidden;
}

.discover-ad-selected {
    width: 45%;
    min-width: 30vw;
    height: 150px;
    color: white;
    text-shadow: 2px 2px 2px #000000;
    font-weight: 500;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-end;
    text-align: left;
    margin-bottom: 5vw;
    border-radius: 7px;
    box-shadow: 2px 5px 7px #000000bf;
    position: relative;
    overflow: hidden;
}

.discover-info-container {
    width: calc(100% - 20px);
    height: 70px;
    background-color: #00000054;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    bottom: 0;
}

.discover-ad-icon-row {
    width: 100% - 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: smaller;
    align-items: center;
    margin-bottom: 4px;
}


/* .feed-ad-name{
} */

.feed-ad-promotion {
    margin-top: 3px;
}

.feed-ad-number-info-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: medium;
    font-weight: normal;
    padding-bottom: 5px;
}

.feed-ad-price {
    margin-left: 5vw;
}

.feed-ad-time-new {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.feed-ad-time-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}

.feed-ad-age {
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 5vw;
}

.feed-ad-footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.feed-ad-footer-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin: auto;
    margin-top: 15px;
}

.feed-ad-tags {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-around;
    font-style: italic;
    font-size: small;
    font-weight: bold;
}

.feed-tag {
    margin: 0px 3px;
}

.feed-ad-purchase-button {
    background: url(./img_resources/icons/purchase-hollow.png);
    background-position: 50% 50%;
    background-size: auto 26px;
    background-repeat: no-repeat;
}

.directions-button {
    background: url(./img_resources/icons/location.png);
    background-position: 50% 50%;
    background-size: auto 26px;
    background-repeat: no-repeat;
}

.like-button {
    background: url(./img_resources/icons/heart-hollow.png);
    background-position: 50% 50%;
    background-size: auto 26px;
    background-repeat: no-repeat;
}

.ad-icon {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    text-decoration: none;
}

@media(max-width:768px) {
    .demo-image-preview>img {
        width: 100%;
    }
}


/* fullscreen enable by props */

.demo-image-preview-fullscreen>img {
    width: 100vw;
    height: 100vh;
}